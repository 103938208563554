import React, {useState, useEffect} from 'react'

const Tab = ({ tabData }) => {
  const [currentTab, setCurrentTab] = useState(0)

  const changeTab = (k) => {
    setCurrentTab(k)
  }

  return (
    <div className=''>
        <div className={`shadow-xl rounded-xl mx-auto max-w-7xl grid justify-center items-start ${tabData[2].color}`}>
            
            <div className='w-full mx-auto flex place-content-center metricsContent'>
              <div className='w-7/12 mx-auto p-5'>
                  <ul className={`grid grid-cols-${tabData[0].length} gap-5 w-full mx-auto relative`}>
                          {
                              tabData[0].map((key, i) => {
                                  return(
                                      <p className="text-gray-500 text-center cursor-pointer text-base pb-4 m-0 mt-2" onClick={() => changeTab(i)}>{tabData[0][i]}</p>
                                  )
                              })
                          }
                          <div className={`absolute bottom-0 transition-all duration-300 border-b border-2 w-1/${tabData[0].length}`} style= {{ left: `${(currentTab/tabData[0].length) * 100}%`, borderColor: `${tabData[1][currentTab].tabColor}` }}></div>
                                  
                  </ul>
                  { 
                    tabData[1].map((key, i) => {
                      if(i === currentTab) {
                          return(
                          <div className='flex mt-10' id="metricsContent1">
                              <div className='my-auto p-5'>
                                <div className='flex'>
                                  <p className={`border rounded-full px-5 py-2 flex place-content-center`} style= {{ borderColor: `${tabData[1][currentTab].tabColor}` }}>
                                    {tabData[1][i].tab}
                                  </p>
                                </div>
                                <p className='lg:w-11/12 text-left my-auto metricsText text-gray-500' dangerouslySetInnerHTML= {{__html: tabData[1][i].desc}}/>
                              </div>
                          </div>
                      )}
                    })
                  }
                  
              </div>
              <div className='w-5/12 flex justify-end mx-auto'>
                { 
                    tabData[1].map((key, i) => {
                      if(i === currentTab) {
                          return(
                          <div className='flex' id="metricsContent1">
                              {/* <div className='w-1/2 my-auto'><p className='lg:w-11/12 text-left my-auto metricsText text-gray-500'>{tabData[1][i].desc}</p></div> */}
                              <div className='w-full h-full flex justify-end relative'>
                                <div className=''>
                                  <picture>
                                      <source className='z-10 relative h-full' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/${tabData[1][i].image}`} media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="500" />
                                      <img className='z-10 relative h-full' src={`https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/${tabData[1][i].image}`} alt="Vantage Perks" width="500" height="572" />
                                  </picture>
                                </div>
                              </div>
                          </div>
                      )}
                    })
                }
              </div>
            </div>
        </div>
    </div>
  )
}

export default Tab
import React from 'react'

const FAQComponent = () => {
  return (
        <div class="xl:pt-5 toc-detail">
                <div class="container max-w-full mt-5 mb-10">
                    <div class="acc relative">
                        <div class="acc__card rounded-lg bg-white border-white shadow border px-5 py-6">
                            <div class="acc__title text-base haschildren toc-heading pl-1 active" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}> 1. How can I apply for a job in Vantage Circle?</div>
                            <div class="acc__panel" style={{display:'block', marginTop:'.2rem', marginBottom: '0rem !important', padding: '0.8rem'}}>
                                <p className='mt-6 p-0 m-0'>
                                Now that you are on our careers page please click on the suitable open positions above and click on <strong>APPLY FOR THIS JOB</strong> tab. It will lead you to a new page where you will be able to upload your resume and fill in a few details about yourself.<br/><br/>
                                Click on the <strong>APPLY FOR THIS JOB</strong> tab and you are done.
                                </p>
                            </div>
                        </div>
                        <div class="acc__card rounded-lg bg-white border-white shadow border px-5 py-6">
                                <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>2. Can I apply for more than one job?</div>
                                <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                    <p className='mt-6 p-0 m-0'>
                                    You may not have to apply for more than one job as we will anyways redirect your CV in case we find it suitable for any other open positions that we are currently hiring for.
                                    </p>
                                </div>
                        </div>
                        <div class="acc__card rounded-lg bg-white border-white shadow border px-5 py-6">
                                <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>3. Why can’t I apply for the same job profile twice?</div>
                                <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                    <p className='mt-6 p-0 m-0'>
                                    This is to avoid duplication of profiles. However if you have any doubt about the successful submission of your application, please feel free to write to <a href="mailto:careers@vantagecircle.com" className="">careers@vantagecircle.com</a> and we will make sure that your application is received by us.
                                    </p>
                                </div>
                        </div>
                        <div class="acc__card rounded-xl bg-white border-white shadow border px-5 py-6">
                                <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>4. What happens after I submit my application?</div>
                                <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                    <p className='mt-6 p-0 m-0'>
                                    After you submit your application, the recruiting team will be checking the resumes, and based on the relevance with the job role, your application will be processed. If shortlisted, you will receive an email from us to continue for further process.
                                    </p>
                                </div>
                        </div>
                        <div class="acc__card rounded-xl bg-white border-white shadow border px-5 py-6">
                                <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>5. How much time does it take to process the applications?</div>
                                <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                    <p className='mt-6 p-0 m-0'>
                                    Our recruiters are trying their best to ensure a smooth and fast process. However, due to the large volume of applications and multiple openings in our organization, it might take up to 30 to 45 days to process the application.
                                    </p>
                                </div>
                        </div>
                        <div class="acc__card rounded-xl bg-white border-white shadow border px-5 py-6">
                                <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>6. How will I know if my application is shortlisted or rejected?</div>
                                <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                    <p className='mt-6 p-0 m-0'>
                                    Once you submit your application, the process of shortlisting is started. The candidates who are shortlisted will receive a call or an email from our end. You will be given certain tasks or called for an interview based on which you will be assessed for further process.<br/><br/>If your application is rejected, you will be receiving an email from our career portal <strong>no-reply@email.keka-mail.com</strong>  mentioning the same.<br/><br/>However, sometimes our mails can be forwarded to the <strong>Spam</strong> section, so kindly check your emails regularly after you submit your application.
                                    </p>
                                </div>
                        </div>
                        <div class="acc__card rounded-xl bg-white border-white shadow border px-5 py-6">
                                <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>7. Is remote working available in Vantage Circle?</div>
                                <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                    <p className='mt-6 p-0 m-0'>
                                    We provide work from home as an option to meet the personal needs of an employee. However, we don’t offer permanent remote working.
                                    </p>
                                </div>
                        </div>
                        <div class="acc__card rounded-xl bg-white border-white shadow border px-5 py-6">
                                <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>8. How will I know the reason for rejecting my application?</div>
                                <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                    <p className='mt-6 p-0 m-0'>
                                    Glad, you asked that. The applications are rejected based on the criteria that are set for the candidate we are looking for.<br/><br/>Your application can be rejected if your profile is not relevant to the job description, or you don't meet the given criteria required for the profile.<br/><br/>Also, the applications are rejected if you submit an incomplete profile or did not submit the resume.
                                    </p>
                                </div>
                        </div>
                        <div class="acc__card rounded-xl bg-white border-white shadow border px-5 py-6">
                            <div class="acc__title text-base haschildren toc-heading pl-1" style={{ fontWeight: '500', color: "black", border: '0px', paddingBottom: '0px' }}>9.&nbsp;How will I be updated about the jobs in Vantage Circle?</div>
                            <div class="acc__panel" style={{display:'none;', marginTop: '.2rem', marginBottom: '0rem !important', padding:'0.8rem'}}>
                                <p className='mt-6 p-0 m-0'>
                                To stay updated about jobs in our organization, you need to check this page regularly. You can also stay updated about new profiles by following our <a target="blank" href="https://www.linkedin.com/company/vantage-circle/">LinkedIn</a> , <a target="blank" href="https://www.instagram.com/vantagecircle/">Instagram</a> or <a target="blank" href="https://www.facebook.com/vantagecircle">Facebook</a> page.<br/><br/>Also, we will soon start sending an update for any new relevant job posted on our career page over an email. Do check your inbox for that.
                                </p>
                                </div>
                        </div>
                    </div>
                </div>
        </div>
  )
}

export default FAQComponent